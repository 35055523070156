import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import endpoint from '../variables/endPoint.js'
import Typography from '@material-ui/core/Typography';



const styles = theme => ({
   root: {   
      margin: theme.spacing(4),
      display: "flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems: "center",
      marginTop: "200px"
    },
});

class FinishPage extends React.Component{

  constructor(props){
    super(props);
  }


  render(){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
            <Typography id="discrete-slider" gutterBottom>
                We notice that you have already participated in this or a similar task. Thank you for your interest.
            </Typography>
      </div>
    );
  }
}

FinishPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FinishPage);