import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import endpoint from '../variables/endPoint.js'
import imageInfo from '../assets/imagesGallery.js'
import Typography from '@material-ui/core/Typography';
import Tile from '../components/Tile.jsx'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const styles = theme => ({
    root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "80%",
    height: "auto",
  },
});

const myImages = [
"https://media.gettyimages.com/photos/glamorous-couple-picture-id171584152?s=2048x2048",
"https://media.gettyimages.com/photos/man-prepares-food-on-camping-stove-picture-id171296251?s=2048x2048",
"https://media.gettyimages.com/photos/empty-soda-can-picture-id163253829?k=6&m=163253829&s=612x612&w=0&h=U6nxIzvjEHjT0x2arhna35LEygnMFaSem7Db_vlSB0o=",
"https://media.gettyimages.com/photos/dr-pepper-soft-drink-bottle-picture-id530939199?s=2048x2048",
"https://media.gettyimages.com/photos/bottles-of-dr-pepper-soft-drinks-move-down-a-production-line-before-picture-id467676687?s=2048x2048",
"https://media.gettyimages.com/photos/distillery-picture-id542741979?s=2048x2048",
"https://media.gettyimages.com/vectors/halloween-bottle-with-poison-labels-vector-id483883882?s=2048x2048",
"https://media.gettyimages.com/vectors/drink-glass-icon-flat-graphic-design-vector-id491512994?s=2048x2048",
"https://media.gettyimages.com/vectors/finance-concept-with-coffee-cup-vector-id463401067?s=2048x2048",
"https://media.gettyimages.com/photos/confetti-and-party-supplies-spraying-out-of-a-champagne-bottle-picture-id153339826?k=6&m=153339826&s=612x612&w=0&h=MPc50Oaq6am4BfsnrPUzidqiQu_afSNsqBKo8PU1o0A=",
"https://media.gettyimages.com/photos/closeup-of-pens-and-pencils-in-a-mug-picture-id57615001?k=6&m=57615001&s=612x612&w=0&h=mcEGCYVGybfezdrP-rj7TO4LLRdS2G40NEqddRojicc=",
"https://media.gettyimages.com/photos/pens-and-pencils-in-glass-container-picture-id170187548?s=2048x2048",
"https://media.gettyimages.com/photos/red-wine-surface-isolated-on-white-picture-id183766975?s=2048x2048",
"https://media.gettyimages.com/photos/fresh-lime-picture-id538077207?s=2048x2048",
"https://media.gettyimages.com/photos/amy-lehpamer-and-cast-perform-on-stage-during-the-sound-of-music-at-picture-id501686986?s=2048x2048",
"https://media.gettyimages.com/photos/grappe-samples-are-under-analysis-on-august-27-2015-in-liergues-as-picture-id485501998?s=2048x2048",
"https://media.gettyimages.com/photos/inigo-cordoba-of-athletic-de-bilbao-competes-for-the-ball-with-daniel-picture-id870570518?s=2048x2048",
"https://media.gettyimages.com/photos/chelsy-davy-attends-the-launch-of-forte-organics-hosted-by-irene-at-picture-id508301122?s=2048x2048",
]


class GalleryPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: "1",
      imageOnShow: imageInfo.slice(0,100)
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e){
    var curInd = parseInt(e.target.value)
    console.log(e.target.value)
    if(curInd != 10)
      this.setState({
        value: curInd.toString(),
        imageOnShow: imageInfo.slice((curInd-1)*100,(curInd-1)*100+100)
      })
    else
      this.setState({
        value: curInd.toString(),
        imageOnShow: imageInfo.slice((curInd-1)*100)
      })

  }

  render() {

    const { classes } = this.props;
   // console.log(imageInfo)
    return (
      <div className={classes.root}>
          <FormControl component="fieldset" style={{position:"fixed", left:"2%", top:"2%"}}>
            <FormLabel component="legend">Page</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={this.state.value} onChange={this.handleChange}>
              <FormControlLabel value="1" control={<Radio />} label="1" />
              <FormControlLabel value="2" control={<Radio />} label="2" />
              <FormControlLabel value="3" control={<Radio />} label="3" />
              <FormControlLabel value="4" control={<Radio />} label="4" />
              <FormControlLabel value="5" control={<Radio />} label="5" />
              <FormControlLabel value="6" control={<Radio />} label="6" />
              <FormControlLabel value="7" control={<Radio />} label="7" />
              <FormControlLabel value="8" control={<Radio />} label="8" />
              <FormControlLabel value="9" control={<Radio />} label="9" />
              <FormControlLabel value="10" control={<Radio />} label="10" />
            </RadioGroup>
          </FormControl>
        <GridList cellHeight={160} className={classes.gridList} cols={6}>
          {myImages.map((tile, index) => (
            <GridListTile key={tile} cols={tile.cols || 1}>
              <img src={tile} alt={tile} />
              {index}
            </GridListTile>
          ))}
        </GridList>
      </div>
    );
  }
}


GalleryPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GalleryPage);