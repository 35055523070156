import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import endpoint from '../variables/endPoint.js'
import Typography from '@material-ui/core/Typography';
import Tile from '../components/Tile.jsx'




const styles = theme => ({
   root: {   
      margin: theme.spacing(4),
      display: "flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems: "center",
      marginTop: "200px"
    },
});

class SuccessPage extends React.Component{

  constructor(props){

    super(props);
    var key = this.props.location.state.rewardKey
    this.state = {
      workerID: 0,
      helperText: "",
      rewardKey: key
    };
    this.handleWorkerIdInput = this.handleWorkerIdInput.bind(this)
    this.checkID = this.checkID.bind(this)
  }

  handleWorkerIdInput(event){
    this.setState({
      workerID: event.target.value
    })
   // console.log(this.state.workerID);
  };

  checkID(event){
    this.props.history.push({
              pathname: "/form",
              state: {
                workerID: this.state.workerID,
                //imageIDs: res.data.imageIDs
              }
            });

  }

  render(){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
            <Typography id="discrete-slider" gutterBottom>
            Thank you for working on the task. Please submit the following code in MTurk to complete the HIT:
            </Typography>
            <span style={{fontSize:"23px"}}><b>{this.state.rewardKey}</b></span>
      </div>
    );
  }
}

SuccessPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SuccessPage);