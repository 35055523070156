
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';


const styles = theme => ({
   root: {   
      margin: theme.spacing(4),
      display: "flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems: "center",
      marginTop: "200px"
    },

    body: {
      margin: "0",
      padding: "0",
      width: "100%",
      height: "100vh",
      fontFamily: 'PT Sans',
      fontSize: "18px"
    },
    
    tiles: {
      padding: "6vh 16vw",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",
      position: "relative"
    },

    tile: {
      margin: "15px",
      cursor: "pointer",
      overflow: "hidden",
      width: "18vw",
      height: "18vw",
      img: {
        width: "100%",
        transition: "transform 500ms ease"
      }
    }
});

class Tile extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        open: false,
        mouseOver: false
      };
      // Bind properties to class instance
      this._clickHandler = this._clickHandler.bind(this);
      this._mouseEnter = this._mouseEnter.bind(this);
      this._mouseLeave = this._mouseLeave.bind(this);
    }
    // Event handlers to modify state values
  _mouseEnter(e) {
    e.preventDefault();
    if (this.state.mouseOver === false) {
      console.log(this.props.data.name);
      this.setState({
        mouseOver: true
      })
    }
  }
  _mouseLeave(e) {
    e.preventDefault();
    if (this.state.mouseOver === true) {
      this.setState({
        mouseOver: false
      })
    }
  }
  _clickHandler(e) {
    e.preventDefault();
    if (this.state.open === false) {
      this.setState({
        open: true
      });
    } else {
      this.setState({
        open: false
      });
    }
  }

  render() {
    // Modify styles based on state values
    let tileStyle = {};
    let headerStyle = {};
    let zoom = {};
    // When tile clicked
    if (this.state.open) {
      tileStyle = {
        width: '62vw',
        height: '62vw',
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '0',
        marginTop: '-31vw',
        marginLeft: '-31vw',
        boxShadow: '0 0 40px 5px rgba(0, 0, 0, 0.3)',
        transform: 'none'
      };
    } else {
      tileStyle = {
        width: '18vw',
        height: '18vw'
      };
    }

    return (
      <div className="tile">
        <img
          onMouseEnter={this._mouseEnter}
          onMouseLeave={this._mouseLeave}
          onClick={this._clickHandler}
          src={this.props.data.image}
          alt={this.props.data.name}
          style={tileStyle}
        />
      </div>
    );
  }
}

Tile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tile);
