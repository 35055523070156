import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TestImage from '../assets/test.png'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioPicker from '../components/RadioPicker.jsx'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Suspense} from 'react-image'
import {useImage} from 'react-image'
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import endpoint from '../variables/endPoint.js'


const styles = theme => ({
   root: {
      display: 'flex', 
      flexDirection: "row", 
      height: "100%",
      marginTop: "3%"
    },

   imgArea:{
      width: "45%",
      marginTop: "3%",
      height: "auto",
      display: "flex",
      flexDirection: "column"

   },

   img:{
    alignSelf: "flex-start"
   },

   formArea:{
      width: "55%",
      marginTop: "3%"
    },

   statusInfo:{
      position: "absolute",
      top: "180px"
   },

   btns:{
     width: "50%"
   },

   btn1:{
     right: "10%"
   },

   btn2:{
     left: "10%"
   }
});



class RateForm extends React.Component{

  constructor(props){
    super(props);

    var imageInfo = this.props.location.state.imageInfo
    var ratingForm = []
    for(let i = 0; i < imageInfo.length; i++){
      imageInfo[i].image_id = (imageInfo[i].image_id).toString()
      var ratingObject = {}
      ratingObject.imgID = imageInfo[i].image_id
      ratingObject.rating = [-1,-1,-1,-1]
      ratingForm.push(ratingObject)
    }

    this.state = {
      workerID: this.props.location.state.workerID,
      imageInfo: imageInfo,
      showLBtn: "None",
      RBtnText: "Next",
      curImgInd: 0,
      curImgUrl: imageInfo[0].url,
      numImgs: imageInfo.length,
      isLoading: true,
      showImg: "none",
      ratingForm:ratingForm,
      disableRBtn: true,
      isSubmitting: false
    };

    this.clickLeft = this.clickLeft.bind(this)
    this.clickRight = this.clickRight.bind(this)
    this.onLoadImg = this.onLoadImg.bind(this)
    this.handleChangeRating = this.handleChangeRating.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }


  loadImageInfo(){
   // console.log("loadImageInfo")
    //call API
    let imageInfo = [{"id":"0", "url":"https://media.gettyimages.com/photos/young-female-painter-painting-canvas-in-studio-picture-id1150708159?s=2048x2048"},{"id":"1", "url":"https://media.gettyimages.com/photos/family-house-sitting-on-tablet-pc-picture-id582763822?s=2048x2048"},{"id":"3", "url":"https://media.gettyimages.com/photos/artist-at-work-in-her-studio-picture-id645377467?s=2048x2048"}]

    this.setState({
      imageInfo: imageInfo,
      numImgs: imageInfo.length,
      curImgUrl: imageInfo[0].url
    })

  }

  onLoadImg(){
    this.setState({
      isLoading: false,
      showImg: true
    })
  }

  clickLeft(){
    let curImgInd = this.state.curImgInd
    let prevUrl = this.state.imageInfo[curImgInd-1].url

    if(curImgInd == 1){
        this.setState({
          curImgUrl: prevUrl,
          curImgInd: curImgInd - 1,
          RBtnText: "Next",
          showLBtn: "None",
          isLoading: true,
          showImg: "none",
          disableRBtn: false
        })
    }

    else{
       this.setState({
          curImgUrl: prevUrl,
          curImgInd: curImgInd - 1,
          RBtnText: "Next",
          isLoading: true,
          showImg: "none",
          disableRBtn: false
        })
    }
  }

  handleChangeRating = name => (e, value) => {
    // this.setState({
    //   [name]: value
    // });
   // console.log(name, parseInt(value), this.state.ratingForm[this.state.curImgInd].rating)
    var ratings = this.state.ratingForm
    ratings[this.state.curImgInd].rating[name] = parseInt(value)

    var curImgFinished = true
    for(let i=0; i < 4; i++){
      if(ratings[this.state.curImgInd].rating[i] == -1){
        curImgFinished = false
        break
      }
    }
    this.setState({
      ratingForm: ratings,
      disableRBtn: !curImgFinished
    })
  }


  clickRight(){
    let curImgInd = this.state.curImgInd
    if(curImgInd+1 < this.state.numImgs){
      //go to next image
      let nextUrl = this.state.imageInfo[curImgInd+1].url

      if(curImgInd + 2 == this.state.numImgs){
        //change btn text
        this.setState({
          curImgUrl: nextUrl,
          curImgInd: curImgInd + 1,
          RBtnText: "Submit",
          isLoading: true,
          showImg: "none"
        })
      }

      if(curImgInd == 0){
        this.setState({
          curImgUrl: nextUrl,
          curImgInd: curImgInd + 1,
          showLBtn: true,
          isLoading: true,
          showImg: "none"
        })
      }

      var disableR = false

      for(let i=0; i < 4; i++){
        if(this.state.ratingForm[this.state.curImgInd+1].rating[0] == -1){
          disableR = true
          break
        }
      }

      //change btn text
      this.setState({
        curImgUrl: nextUrl,
        curImgInd: curImgInd + 1,
        isLoading: true,
        disableRBtn: disableR,
        showImg: "none"
      })
    }
    else{
      //submit
      this.submitForm()
    }
  }


  submitForm(){
    this.setState({
      isSubmitting: true,
      RBtnText: "Loading"
    })
   // console.log("submit: ", this.state.ratingForm)
    fetch( endpoint +"/user/submitForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        workerID: this.state.workerID,
        ratingContent: this.state.ratingForm
      })
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          isSubmitting: false
        })
        if (res.err) {
          this.setState({
            RBtnText: "Submit"
          })
        } else {
            this.props.history.push({
              pathname: "/success",
              state: {
                rewardKey: res.data[0].reward_key
              }
            });      
        }
      });
  }

  render(){
    const {classes} = this.props
  //  console.log("shit", this.state.imageInfo)
    return (
      <div class={classes.root}>
            <Paper elevation={0} />
        <div class={classes.imgArea}>
         
      <Typography id="discrete-slider"  variant="h6" gutterBottom style={{ marginLeft:"auto", marginRight:"auto", width:"80%", fontSize: "1.2vw", marginBottom:"2%", fontStyle:"Italic", textDecoration:"underline"}} >
              Image {this.state.curImgInd+1} of {this.state.numImgs}: Please rate this image for being used in beverage-related articles.
          </Typography>
        {(this.state.isLoading)?<CircularProgress style={{marginLeft:"auto", marginRight:"auto", marginTop:"10%"}}/>:""}
          <img class={classes.img} src={this.state.curImgUrl} onLoad={this.onLoadImg}  style={{marginLeft:"auto", marginRight:"auto", maxHeight:"550px", maxWidth:"80%", height:"auto", border:"2px solid grey", display:this.state.showImg}}/>
        </div>
         
        <div class={classes.formArea} style={{display:this.state.showImg}}>
          <div>
              <Typography id="discrete-slider" gutterBottom style={{fontSize: "1.2vw",fontWeight:"bold"}}>
                  Novelty: This image is original for beverage-related articles.
              </Typography> 
              <RadioPicker  labelColor="#4153af" radioColor="primary" value={this.state.ratingForm[this.state.curImgInd].rating[0]}  handleSelect={this.handleChangeRating(0)}/>
              <Divider  style={{width:"90%", marginTop: "-2%", marginBottom: "2%"}}/>
            <Typography id="discrete-slider" gutterBottom  style={{fontSize: "1.2vw", fontWeight:"bold"}}>
                Feasibility: This image is practical for beverage-related articles. 
            </Typography>
            <RadioPicker  labelColor="#4153af" radioColor="primary" value={this.state.ratingForm[this.state.curImgInd].rating[1]}  handleSelect={this.handleChangeRating(1)}/>
            <Divider  style={{width:"90%", marginTop: "-2%", marginBottom: "2%"}}/>
            <Typography id="discrete-slider" gutterBottom  style={{fontSize: "1.2vw", fontWeight:"bold"}}>
                Attractiveness: This image is esthetically appealing for beverage-related articles.
            </Typography>
            <RadioPicker labelColor="#4153af" radioColor="primary" value={this.state.ratingForm[this.state.curImgInd].rating[2]}  handleSelect={this.handleChangeRating(2)}/>
            <Divider style={{width:"90%", marginTop: "-2%", marginBottom: "2%"}}/>
             <Typography id="discrete-slider" gutterBottom style={{fontSize: "1.2vw", fontWeight:"bold"}}>
                Overall Quality: This image is of high-quality for beverage-related articles.            
              </Typography>
            <RadioPicker labelColor="#4153af" radioColor="primary" value={this.state.ratingForm[this.state.curImgInd].rating[3]}  handleSelect={this.handleChangeRating(3)}/>
          </div>
          <div class={classes.btns}>
                <Button variant="contained" onClick={this.clickLeft} size="large" style={{position:"absolute", left:"45%", display:this.state.showLBtn}}>Previous</Button>
                <Button variant="contained" onClick={this.clickRight} size="large" style={{ position:"absolute", right:"9%"}} disabled={this.state.disableRBtn||this.state.isSubmitting}>{this.state.RBtnText}</Button>
          </div>
        </div>
      </div>
    );
  }
}

RateForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RateForm);
