import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TestImage from '../assets/test.png'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
   root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 300,
      marginTop: '20%'
    },

    '&$label': {
      fontSize:"1.2vw",
      color: "red"
    }
  },

  label: {
      fontSize:"1.2vw"
  }

});

class RadioPicker extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      selections: [4,4,4]
    };
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect(event){
    console.log(event.target.name + event.target.value)
    this.setState({
      selections:[parseInt(event.target.value),4, 4]
    })
    console.log(this.state.selections)
  }


  render(){
      const { classes } = this.props;
      let labelColor = this.props.labelColor
      let radioColor = this.props.radioColor
      console.log(classes)

    return (
        <div style={{ marginBottom:"4%" }}>
          <RadioGroup name={this.props.name} value={this.props.value} onChange={this.props.handleSelect} row style={{alignItems: 'bottom'}}>
            <div style={{marginTop:"15px"}}>
              {this.props.questionText}
            </div>
            <FormControlLabel
              value={1}
              control={<Radio color={radioColor}/>}
              label="Very Low"
              labelPlacement="top"
              classes={{
                label: classes.label, // class name, e.g. `disabled-x`
              }}
              style={{color:labelColor,marginRight: "-2.5%"}}
            />
            <FormControlLabel
              value={2}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              style={{color:labelColor,marginRight: "-1%", fonSize:"1.2vw"}}
            />
            <FormControlLabel
              value={3}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              style={{color:labelColor,marginRight: "-1%"}}
            />
            <FormControlLabel
              value={4}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              style={{color:labelColor,marginRight: "-2%"}}
            />
            <FormControlLabel
              value={5}
              label="Average"
              style={{color:"grey"}}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              classes={{
                label: classes.label, // class name, e.g. `disabled-x`
              }}
              style={{color:labelColor,marginRight: "-2%"}}
            />
            <FormControlLabel
              value={6}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              style={{color:labelColor,marginRight: "-1%"}}
            />
            <FormControlLabel
              value={7}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              style={{color:labelColor,marginRight: "-1%"}}
            />
            <FormControlLabel
              value={8}
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              style={{color:labelColor,marginRight: "-2.5%"}}
            />
            <FormControlLabel
              value={9}
              label="Very High"
              control={<Radio color={radioColor} />}
              labelPlacement="top"
              classes={{
                label: classes.label, // class name, e.g. `disabled-x`
              }}
              style={{color:labelColor}}
            />
            </RadioGroup>
        </div>
    );
  }
}

RadioPicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioPicker);