import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import endpoint from '../variables/endPoint.js'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MediaQuery, {useMediaQuery} from 'react-responsive'
import SampleImage from '../assets/sample.jpg';



const styles = theme => ({
   root: {   
      margin: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "3%",
      height: "100%"
    },
   text:{
    width: "55%",
    display: "inline-block",
    textAlign:"distributed"
   },

   bottom:{
    display: "flex",
    flexDirection: "row"
   }
});

class LoginPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      workerID: 0,
      helperText: "",
      haveError: false,
      isLoading: false,
      innerWidth: 0,
      innerHeight: 0
    };
    this.handleWorkerIdInput = this.handleWorkerIdInput.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.checkID = this.checkID.bind(this)
  }

  handleWorkerIdInput(event){
    this.setState({
      workerID: event.target.value
    })
   // console.log(this.state.workerID);
  };

  checkID(event){
    this.setState({
      isLoading: true
    })
    fetch( endpoint +"/user/verifyID", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        workerID: this.state.workerID,
        type: "amt"
      })
    })
      .then(res => res.json())
      .then(res => {
      //  console.log(res)
        this.setState({
          isLoading: false
        })
        if (res.err) {
          this.props.history.push({
              pathname: "/finish"
            });

        } else {
            //console.log(res.data.imageInfo)
            this.props.history.push({
              pathname: "/form",
              state: {
                workerID: this.state.workerID,
                imageInfo: res.data.imageInfo

              }
            });
        }
      });

  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    console.log(window.innerWidth, window.innerHeight)
  }

  render(){
    const { classes } = this.props;

    return (
      <div className={classes.root}>
         <Typography className={classes.text} gutterBottom>
              Your task is to evaluate 16 of about one thousand images that a company is considering to be included in its articles about beverages. You will rate each image in terms of its (1) <u>novelty</u> (i.e., originality for beverage-related articles), (2) <u>feasibility</u> (i.e., practical for beverage-related articles), (3) <u>attractiveness</u> (i.e., aesthetically appealing for beverage-related articles), and (4) <u>overall quality</u>. 
          </Typography>
          <Typography className={classes.text} gutterBottom style={{marginTop: "1%"}}>
              Following are some of the images that the company is considering. <span style={{color:"red"}}>Note that these images may not be the ones that you will be evaluating.</span>
          </Typography>
        <img src={SampleImage} style={{width:"55%", marginTop: "2%"}}/>
           <Typography className={classes.text} gutterBottom style={{marginTop: "2%"}}>
              To begin the task, provide your Worker ID:
           </Typography>
           <div className={classes.bottom}>
              <TextField id="standard-basic" 
              error={this.state.haveError}
              onChange={this.handleWorkerIdInput} 
              label="Input your worker ID" 
              helperText={this.state.helperText} 
              style={{marginRight:"30px"}}/>
              <Button variant="contained" size="large" onClick={this.checkID} style={{marginTop:"2%"}} disabled={this.state.isLoading}>
              {(this.state.isLoading)?"Loading":"Start the Task"}
              </Button>
           </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginPage);