import React, {useState} from 'react';
import { Switch, Route} from 'react-router-dom';
import logo from './logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RateForm from './pages/RateFormPage.jsx'
import LoginPage from './pages/LoginPage.jsx'
import SuccessPage from './pages/SuccessPage.jsx'
import FinishPage from './pages/FinishPage.jsx'
import GalleryPage from './pages/GalleryPage.jsx'


const App = () => (
  <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route path="/form" component={RateForm} />
      <Route path="/success" component={SuccessPage} />
      <Route path="/finish" component={FinishPage} />
      <Route path="/gallery" component={GalleryPage} />
  </Switch>
);


export default App;

